<template>
  <div class="layout-nav">
    <van-tabbar active-color="#da1b2d" route v-model="active">
      <van-tabbar-item replace to="/">
        <template #icon="props">
          <!-- <img :src="props.active ? icon.active1 : icon.inactive1" /> -->
          <van-icon name="home-o" />
        </template>
        500图库
      </van-tabbar-item>
      <van-tabbar-item replace to="/find">
        <template #icon="props">
          <!-- <img :src="props.active ? icon.active2 : icon.inactive2" /> -->
          <van-icon name="eye-o" />
        </template>
        发现
      </van-tabbar-item>
      <van-tabbar-item to="/wechat">
        <div class="tabs-3">
          <div class="iconImg">
            <img :src="icon.active3" />
          </div>
          <!-- <van-icon size="40" name="chat-o" /> -->
          <div>聊天室</div>
        </div>
      </van-tabbar-item>
      <van-tabbar-item replace to="/website">
        <template #icon="props">
          <!-- <img :src="props.active ? icon.active4 : icon.inactive4" /> -->
          <van-icon name="gem-o" />
        </template>
        寻宝
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <template #icon="props">
          <!-- <img :src="props.active ? icon.active5 : icon.inactive5" /> -->
          <!-- <van-icon name="contact-o" /> -->
          <van-icon name="user-o" />
        </template>
        我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active1: require('@/assets/images/nav1-2.png'),
        inactive1: require('@/assets/images/nav1-1.png'),
        active2: require('@/assets/images/nav2-2.png'),
        inactive2: require('@/assets/images/nav2-1.png'),
        active3: require('@/assets/images/nav3-1.png'),
        active4: require('@/assets/images/nav4-2.png'),
        inactive4: require('@/assets/images/nav4-1.png'),
        active5: require('@/assets/images/nav5-2.png'),
        inactive5: require('@/assets/images/nav5-1.png'),
      },
    }
  },
}
</script>

<style scoped>
.layout-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.iconImg {
  padding-top: 0.13rem;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: border-box;
  width: 0.9rem;
  margin: 0 auto;
}
.layout-nav .van-tabbar {
  max-width: 480px;
  margin: 0 auto;
  box-shadow: 0 0.02rem 0.08rem 0 hsla(0, 0%, 62.7%, 0.5);
}
.layout-nav /deep/.van-tabbar-item__text {
  position: relative;
}
.layout-nav /deep/.van-tabbar-item--active,
.layout-nav /deep/.van-tabbar-item--active .tabs-3 {
  color: #da1b2d;
}
.layout-nav .tabs-3 {
  position: absolute;
  left: -0.6rem;
  top: -0.76rem;
  border-radius: 100%;
  text-align: center;
  z-index: 1;
  width: 1.2rem;
  color: #646566;
}
.layout-nav .tabs-3 img {
  width: 0.8rem;
}
.layout-nav .tabs-3 div {
  padding-top: 0.04rem;
}
@media screen and (min-width: 768px) {
  .chatrom,
  .chatwarp,
  .fixedwidth,
  .layout-nav,
  .pickcode-result,
  .pushsetclass,
  .tan-pop,
  .van-nav-bar {
    max-width: 480px;
    left: 50% !important;
    transform: translate(-50%);
  }
}
</style>
