import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import store from './store/store.js'
// import 'amfe-flexible';
import Vant from 'vant'
import { Lazyload, Toast, Dialog } from 'vant'
// import {  } from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'
import logo from './assets/images/logo.png'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import 'leader-line'
Vue.use(VueQuillEditor, {
  placeholder: '请输入内容',
})
Vue.use(Vant)
// Vue.use(Lazyload)
Vue.use(Toast)
Vue.use(Dialog)
Vue.config.productionTip = false
// 注册时可以配置额外的选项
// console.log(store, 'storeApp')

Vue.use(Lazyload)
new Vue({
  axios,
  router,
  // store,
  store,
  render: h => h(App),
}).$mount('#app')
