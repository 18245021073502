import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'
import { Toast } from 'vant'
Vue.use(VueRouter)

const routes = [...router_modular]

const router = new VueRouter({
  routes,
})
//全局路由守卫
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')).token : ''
  console.log(token)

  if (to.path == '/wechat') {
    if (token) {
      next()
    } else {
      Toast('请先登录')
      next('/login')
    }
  } else {
    next()
  }
})

// 监听路由变化并设置标题
router.afterEach(to => {
  document.title = localStorage.getItem('title') || '六合图库'
})
export default router
