import HomeView from '@/views/Index/Index.vue'

const router_modular = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },

  {
    path: '/find',
    name: 'find',
    component: () => import('@/views/find/find.vue'),
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/views/market/market.vue'),
  },
  {
    path: '/website',
    name: 'website',
    component: () => import('@/views/website/website.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine/mine.vue'),
  },
  {
    path: '/homeSearch',
    name: 'homeSearch',
    component: () => import('@/views/Index/components/homeSearch.vue'),
  },
  {
    path: '/lottery',
    name: 'lottery',
    component: () => import('@/views/Index/components/lottery.vue'),
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/website/components/apply.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginView.vue'),
  },
  {
    path: '/forgotpwd',
    name: 'forgotpwd',
    component: () => import('@/views/login/forgotpwd.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/RegiSter.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/mine/components/account.vue'),
  },
  {
    path: '/red-record',
    name: 'red-record',
    component: () => import('@/views/mine/components/redRecord.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/user.vue'),
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () => import('@/views/recommend/IndexView.vue'),
  },
  {
    path: '/playback',
    name: 'playback',
    component: () => import('@/views/playback/IndexView.vue'),
  },
  {
    path: '/corpus',
    name: 'corpus',
    component: () => import('@/views/corpus/IndexView.vue'),
  },
  {
    path: '/corpusdetaillist',
    name: 'corpusdetaillist',
    component: () => import('@/views/corpusdetaillist/IndexView.vue'),
  },
  {
    path: '/corpus-search',
    name: 'corpusSearch',
    component: () => import('@/views/corpusdetaillist/CorpusSearch.vue'),
  },
  {
    path: '/corpusdetail',
    name: 'corpusdetail',
    component: () => import('@/views/corpusdetail/IndexView.vue'),
  },
  {
    path: '/consultation',
    name: 'consultation',
    component: () => import('@/views/consultation/IndexView.vue'),
    meta: { keepAlive: false },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/help/IndexView.vue'),
  },
  {
    path: '/picture',
    name: 'picture',
    component: () => import('@/views/picture/IndexView.vue'),
  },
  {
    path: '/pictureComments',
    name: 'pictureComments',
    component: () => import('@/views/pictureComments/IndexView.vue'),
  },
  {
    path: '/tuku',
    name: 'tuku',
    component: () => import('@/views/tuku/IndexView.vue'),
  },
  {
    path: '/xilie',
    name: 'xilie',
    component: () => import('@/views/xilie/IndexView.vue'),
  },
  {
    path: '/pictureJctj',
    name: 'pictureJctj',
    component: () => import('@/views/pictureJctj/IndexView.vue'),
  },
  {
    path: '/vote',
    name: 'vote',
    component: () => import('@/views/vote/IndexView.vue'),
  },
  {
    path: '/expertForum',
    name: 'expertForum',
    component: () => import('@/views/expertForum/IndexView.vue'),
  },
  {
    path: '/ltSearch',
    name: 'ltSearch',
    component: () => import('@/views/expertForum/LtSearch.vue'),
  },
  {
    path: '/zxcount',
    name: 'zxcount',
    component: () => import('@/views/zxcount/IndexView.vue'),
    redirect: '/zxcount/liuhe', // 重定向到子路由 child-a
    children: [
      {
        path: 'liuhe', // 访问 /parent/liuhe
        name: 'liuhe',
        component: () => import('@/views/zxcount/LiuheView.vue'),
      },
      {
        path: 'attribute', // 访问 /parent/liuhe
        name: 'attribute',
        component: () => import('@/views/zxcount/AttributeView.vue'),
      },
      {
        path: 'specialcode', // 访问 /parent/liuhe
        name: 'specialcode',
        component: () => import('@/views/zxcount/SpecialCode.vue'),
      },
      {
        path: 'positivecode', // 访问 /parent/liuhe
        name: 'positivecode',
        component: () => import('@/views/zxcount/PositiveCode.vue'),
      },
      {
        path: 'wstable', // 访问 /parent/liuhe
        name: 'wstable',
        component: () => import('@/views/zxcount/WsTable.vue'),
      },
      {
        path: 'sxpiechart', // 访问 /parent/liuhe
        name: 'sxpiechart',
        component: () => import('@/views/zxcount/SxpiEchart.vue'),
      },
      {
        path: 'sxpiechart2', // 访问 /parent/liuhe
        name: 'sxpiechart2',
        component: () => import('@/views/zxcount/SxpiEchart2.vue'),
      },
      {
        path: 'bspiechart2', // 访问 /parent/liuhe
        name: 'bspiechart2',
        component: () => import('@/views/zxcount/BspiEchart2.vue'),
      },
      {
        path: 'bspiechart1', // 访问 /parent/liuhe
        name: 'bspiechart1',
        component: () => import('@/views/zxcount/BspiEchart1.vue'),
      },
      {
        path: 'barchart2', // 访问 /parent/liuhe
        name: 'barchart2',
        component: () => import('@/views/zxcount/BarChart2.vue'),
      },
      {
        path: 'barchart1', // 访问 /parent/liuhe
        name: 'barchart1',
        component: () => import('@/views/zxcount/BarChart1.vue'),
      },
      {
        path: 'wspiechart2', // 访问 /parent/liuhe
        name: 'wspiechart2',
        component: () => import('@/views/zxcount/WspiEchart2.vue'),
      },
      {
        path: 'wspiechart1', // 访问 /parent/liuhe
        name: 'wspiechart1',
        component: () => import('@/views/zxcount/WspiEchart1.vue'),
      },
      {
        path: 'hmpiechart', // 访问 /parent/liuhe
        name: 'hmpiechart',
        component: () => import('@/views/zxcount/HmpiEchart.vue'),
      },
      {
        path: 'jqystable', // 访问 /parent/liuhe
        name: 'jqystable',
        component: () => import('@/views/zxcount/JqysTable.vue'),
      },
      {
        path: 'lmtable', // 访问 /parent/liuhe
        name: 'lmtable',
        component: () => import('@/views/zxcount/LmTable.vue'),
      },
      {
        path: 'lxtable', // 访问 /parent/liuhe
        name: 'lxtable',
        component: () => import('@/views/zxcount/LxTable.vue'),
      },
    ],
  },
  {
    path: '/toolbox',
    name: 'toolbox',
    component: () => import('@/views/toolbox/ToolBox.vue'),
  },
  {
    path: '/lover',
    name: 'lover',
    component: () => import('@/views/toolbox/LoverView.vue'),
  },
  {
    path: '/cnzodiac',
    name: 'cnzodiac',
    component: () => import('@/views/toolbox/CnzoDiac.vue'),
  },
  {
    path: '/shake',
    name: 'shake',
    component: () => import('@/views/toolbox/ShaKe.vue'),
  },
  {
    path: '/silkbag',
    name: 'silkbag',
    component: () => import('@/views/toolbox/SilkBag.vue'),
  },
  {
    path: '/luck',
    name: 'luck',
    component: () => import('@/views/toolbox/LuCk.vue'),
  },
  {
    path: '/turntable',
    name: 'turntable',
    component: () => import('@/views/toolbox/TurnTable.vue'),
  },
  {
    path: '/date',
    name: 'date',
    component: () => import('@/views/toolbox/DateView.vue'),
  },
  {
    path: '/secret',
    name: 'secret',
    component: () => import('@/views/toolbox/SecretView.vue'),
  },
  {
    path: '/pickcode',
    name: 'pickcode',
    component: () => import('@/views/toolbox/PickCode.vue'),
  },
  {
    path: '/silkbagl',
    name: 'silkbagl',
    component: () => import('@/views/toolbox/SilkBagl.vue'),
  },
  {
    path: '/fatie',
    name: 'fatie',
    component: () => import('@/views/expertForum/FaTie.vue'),
  },
  {
    path: '/myset',
    name: 'myset',
    component: () => import('@/views/mine/MySet.vue'),
  },
  {
    path: '/setphone',
    name: 'setphone',
    component: () => import('@/views/mine/SetPhone.vue'),
  },
  {
    path: '/setname',
    name: 'setname',
    component: () => import('@/views/mine/SetName.vue'),
  },
  {
    path: '/setpwd',
    name: 'setpwd',
    component: () => import('@/views/mine/SetPwd.vue'),
  },
  {
    path: '/findDetail',
    name: 'findDetail',
    component: () => import('@/views/findDetail/FindDetail.vue'),
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/ActivityView.vue'),
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    component: () => import('@/views/activity/ActivityDetail.vue'),
  },
  {
    path: '/bindingAccount',
    name: 'bindingAccount',
    component: () => import('@/views/bindingAccount/BindingAccount.vue'),
  },
  {
    path: '/grade',
    name: 'grade',
    component: () => import('@/views/me/GradeView.vue'),
  },
  {
    path: '/collect',
    name: 'collect',
    component: () => import('@/views/me/CollectView.vue'),
  },
  {
    path: '/upvote',
    name: 'upvote',
    component: () => import('@/views/me/UpvoteView.vue'),
  },
  {
    path: '/follow',
    name: 'follow',
    component: () => import('@/views/me/FollowView.vue'),
  },
  {
    path: '/fans',
    name: 'fans',
    component: () => import('@/views/me/FansView.vue'),
  },
  {
    path: '/fansPh',
    name: 'fansPh',
    component: () => import('@/views/me/FansPh.vue'),
  },
  {
    path: '/gradePh',
    name: 'gradePh',
    component: () => import('@/views/me/GradePh.vue'),
  },
  {
    path: '/giftPh',
    name: 'giftPh',
    component: () => import('@/views/me/GiftPh.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/me/NoticeView.vue'),
  },
  {
    path: '/kf',
    name: 'kf',
    component: () => import('@/views/me/KfView.vue'),
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/me/RecordView.vue'),
  },
  {
    path: '/recordDetails',
    name: 'recordDetails',
    component: () => import('@/views/me/RecordDetails.vue'),
  },
  {
    path: '/zjPassword',
    name: 'zjPassword',
    component: () => import('@/views/mine/ZjPassword.vue'),
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/mine/InviteView.vue'),
  },
  {
    path: '/addZjpass',
    name: 'addZjpass',
    component: () => import('@/views/mine/AddZjpass.vue'),
  },
  {
    path: '/alternateUrl',
    name: 'alternateUrl',
    component: () => import('@/views/alternateUrl/AlternateUrl.vue'),
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('@/views/user/UserInfo.vue'),
  },
  {
    path: '/release',
    name: 'release',
    component: () => import('@/views/me/ReleaseView.vue'),
  },
  {
    path: '/comment',
    name: 'comment',
    component: () => import('@/views/me/CommentView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/me/AboutView.vue'),
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/views/me/FeedBack.vue'),
  },
  {
    path: '/myFb',
    name: 'myFb',
    component: () => import('@/views/me/MyFbView.vue'),
  },
  {
    path: '/wechat',
    name: 'wechat',
    component: () => import('@/views/WechatView.vue'),
  },
  {
    path: '/extension',
    name: 'extension',
    component: () => import('@/views/me/ExtensionView.vue'),
  },
  {
    path: '/xiazai',
    name: 'xiazai',
    component: () => import('@/views/me/AppXiazai.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/me/ReportView.vue'),
  },
  {
    path: '/topshare',
    name: 'topshare',
    component: () => import('@/views/me/TopshareView.vue'),
  },
  {
    path: '/thisFans',
    name: 'thisFans',
    component: () => import('@/views/user/ThisFans.vue'),
  },
  {
    path: '/thisAttention',
    name: 'thisAttention',
    component: () => import('@/views/user/ThisAttention.vue'),
  },
]
export default router_modular
