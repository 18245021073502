import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    refreshHomePage: false, // 用于控制首页是否需要刷新
  },
  getters: {
    getRefreshHomePage: state => state.refreshHomePage,
  },
  mutations: {
    setRefreshHomePage(state, value) {
      state.refreshHomePage = value
    },
  },
  actions: {
    // 触发刷新首页的操作
    triggerRefreshHomePage({ commit }, value) {
      commit('setRefreshHomePage', value)
    },
  },
})
