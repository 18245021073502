import http from '../utils/http'
const Api = {
  // 活动列表
  getActivityList() {
    return http.get('/api/activity/index')
  },
  // 签到活动
  signInActivity() {
    return http.get('/api/activity/sign_info')
  },
  // 签到
  signIn() {
    return http.get('/api/activity/sign')
  },
  // 领取奖励
  getReward(status, money) {
    return http.get(`/api/activity/claim_rewards?status=${status}&money=${money}`)
  },
}
export default Api
