import http from '../utils/http'
const Api = {
  // 注册
  registerApi(data) {
    return http.post('/api/user/register', data)
  },
  // 登录
  loginApi(data) {
    return http.post('/api/user/login', data)
  },
  // 手机号登录
  phoneLoginApi(data) {
    return http.post('/api/user/mobilelogin', data)
  },
  // 修改个人信息
  changeInfoApi(data) {
    return http.post('/api/user/profile', data)
  },
  // 发送验证码
  sendCodeApi(mobile, event) {
    return http.post('/api/sms/send', { mobile, event })
  },
  // 会员中心
  getUserInfoApi() {
    return http.get('/api/user/index')
  },
  // 重置资金密码 /api/user/resetpaypwd
  resetPayPwdApi(data) {
    return http.post('/api/user/resetpaypwd', data)
  },
  // 分享
  shareApi() {
    return http.get('/api/user/share')
  },
}
export default Api
